import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementBlock as _createElementBlock, unref as _unref, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 2 }
const _hoisted_2 = { class: "container-iframe" }
const _hoisted_3 = ["height"]

import { computed, onBeforeMount, onMounted, ref, watch } from 'vue';
import useProfileStore from '@/store/profile/useProfileStore';
import AppHeader from '@/components/app/AppHeader/AppHeader.vue';
import AppAbortConfirmationModal from '@/components/app/AppAbortConfirmationModal/AppAbortConfirmationModal.vue';
import usePostHogEvent from '@/composables/usePostHogEvent/usePostHogEvent';
import useSharedStore from '@/store/shared/useSharedStore';
import useRegisterWorkStore from '@/store/registerWork/useRegisterWorkStore';
import { HeaderType } from '@/shared/types/generic';
import useErrorLogStore from '@/store/errorLog/useErrorLogStore';
import router from '../../router';


export default /*@__PURE__*/_defineComponent({
  __name: 'MainView',
  setup(__props, { expose: __expose }) {

const sharedStore = useSharedStore();
const profileStore = useProfileStore();
const useRegisteWorkHour = useRegisterWorkStore();
const errorLog = useErrorLogStore();
const isDashBoard = ref(false);
const screenHeight = window.innerHeight;

function saveAutoCapturePostHog(eventName: string, execute: boolean) {
  if (execute) {
    usePostHogEvent().postHogAutoCaptureEvent(eventName);
  }
}

const showHeader = computed<boolean>(() => {
  return profileStore.autenthicated.firstLogin;
});

const showDashboardPage = computed<boolean>(() => {
  return profileStore.autenthicated.isDashBoard;
});

onMounted(() => {
  document.addEventListener('click', (event) => {
    const clickedElement = event.target as HTMLElement;
    const element = clickedElement.localName;
    const eventName = `clicked ${element} with name ${clickedElement.textContent}`;
    saveAutoCapturePostHog(eventName || '', true);
  });
});

function toggleActiveTab(tab: string) {
  if (tab === 'Dashboard') {
    profileStore.autenthicated.isDashBoard = true;
  } else {
    profileStore.autenthicated.isDashBoard = false;
  }
}

onBeforeMount(() => {
  router.beforeEach((to) => {
    document.body.className = '';
    document.body.className = String(to.meta.bodyClass);
  });

  window.removeEventListener('click', (event) => {
    const clickedElement = event.target as HTMLElement;
    const element = clickedElement.localName;
    const eventName = `clicked ${element} with name ${clickedElement.textContent}`;
    saveAutoCapturePostHog(eventName || '', false);
  });
});

const iframeURL = `${process.env.VUE_APP_IFRAME_URL}${process.env.VUE_APP_ORG}`;

const showCorrectionTime = computed({
  get(): boolean {
    return sharedStore.isModalVisible;
  },
  set(newValue: boolean): void {
    sharedStore.isModalVisible = newValue;
  },
});

watch(useRegisteWorkHour.registerPost, (newValue) => {
  if (
    !newValue.register &&
    !newValue.motiveRegister &&
    (!profileStore.profile.currentTab ||
      profileStore.profile.currentTab === HeaderType.DASHBOARD)
  ) {
    profileStore.autenthicated.isDashBoard = true;
  }
});

watch(errorLog.errorLog, (newValue) => {
  if (
    !newValue.errorCause &&
    !newValue.errorLocation &&
    !newValue.errorSubLocation &&
    !newValue.errorType &&
    !newValue.palletType &&
    (!profileStore.profile.currentTab ||
      profileStore.profile.currentTab === HeaderType.DASHBOARD)
  ) {
    profileStore.autenthicated.isDashBoard = true;
  }
});

__expose({
  toggleActiveTab,
  isDashBoard,
  saveAutoCapturePostHog,
  showCorrectionTime,
});

return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (showCorrectionTime.value)
      ? (_openBlock(), _createBlock(AppAbortConfirmationModal, { key: 0 }))
      : _createCommentVNode("", true),
    (showHeader.value)
      ? (_openBlock(), _createBlock(AppHeader, {
          key: 1,
          onToggleActiveTab: toggleActiveTab
        }))
      : _createCommentVNode("", true),
    (!showDashboardPage.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_router_view)
        ]))
      : _createCommentVNode("", true),
    _withDirectives(_createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", null, [
        _createElementVNode("iframe", {
          id: "public-displays",
          title: "publicdisplays",
          src: iframeURL,
          class: "iframe",
          height: _unref(screenHeight) - 68
        }, null, 8, _hoisted_3)
      ])
    ], 512), [
      [_vShow, showDashboardPage.value]
    ])
  ], 64))
}
}

})
<script setup lang="ts">
import { Ref, computed, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { vaibeLogo } from '@/shared/constants/icons';
import useProfileStore from '@/store/profile/useProfileStore';
import { useRouter } from 'vue-router';
import useRegisterWorkStore from '@/store/registerWork/useRegisterWorkStore';
import { HeaderType } from '@/shared/types/generic';
import useErrorLogStore from '@/store/errorLog/useErrorLogStore';
import AppCancelModal from '../AppCancelModal/AppCancelModal.vue';

const { t } = useI18n();
const profileStore = useProfileStore();
const useRegisteWorkHour = useRegisterWorkStore();
const errorLogStore = useErrorLogStore();
const router = useRouter();

const emit = defineEmits(['toggleActiveTab']);

const showCancelModal: Ref<boolean> = ref(false);
const activeTab = ref(HeaderType.DASHBOARD);
const temporaryTab = ref(HeaderType.DASHBOARD);

const hasEditingErrorHandling = computed(
  () =>
    errorLogStore.errorLog.errorCause ||
    errorLogStore.errorLog.errorLocation ||
    errorLogStore.errorLog.errorSubLocation ||
    errorLogStore.errorLog.errorType ||
    errorLogStore.errorLog.palletType,
);

const hasEditingRegisterWorkHour = computed(
  () =>
    useRegisteWorkHour.registerPost.register ||
    useRegisteWorkHour.registerPost.motiveRegister,
);

const clearRegisterWoks = (): void => {
  useRegisteWorkHour.registerPost.register = '';
  useRegisteWorkHour.registerPost.motiveRegister = '';
};

const clearProfile = (): void => {
  profileStore.profile.avatarUrl = '';
  profileStore.profile.name = '';
  profileStore.profile.role = '';
  profileStore.profile.token = '';
};

function toggleActiveTab(tab: HeaderType) {
  if (hasEditingErrorHandling.value && tab !== HeaderType.ERRORHANDLING) {
    showCancelModal.value = true;
    temporaryTab.value = tab;
  } else if (
    hasEditingRegisterWorkHour.value &&
    tab !== HeaderType.ENTRIESEXITS
  ) {
    showCancelModal.value = true;
    temporaryTab.value = tab;
  } else {
    activeTab.value = tab;
    profileStore.profile.currentTab = tab;
    clearRegisterWoks();
    errorLogStore.$reset();
    clearProfile();
    if (tab !== 'Dashboard') {
      router.push('/login');
    }
    emit('toggleActiveTab', activeTab.value);
  }
}

const toggleModalStatus = () => {
  showCancelModal.value = !showCancelModal.value;
};

const goToLoginPage = () => {
  activeTab.value = temporaryTab.value;
  profileStore.profile.currentTab = activeTab.value;
  toggleModalStatus();
  clearRegisterWoks();
  errorLogStore.$reset();
  clearProfile();
};

watch(useRegisteWorkHour.registerPost, (newValue) => {
  if (
    !newValue.register &&
    !newValue.motiveRegister &&
    !profileStore.profile.currentTab
  ) {
    activeTab.value = HeaderType.DASHBOARD;
  }
});

defineExpose({
  activeTab,
  toggleActiveTab,
});
</script>

<template>
  <div class="app-header__wrapper">
    <div class="app-header__content">
      <div class="app-header__left">
        <img :src="vaibeLogo" alt="vaibe logo" />
        <div class="app-header__tabs">
          <div
            :class="[
              'app-header__tabs__single',
              { 'app-header__tabs__single__active': activeTab === 'Dashboard' },
            ]"
            @click="toggleActiveTab(HeaderType.DASHBOARD)"
            @keydown="toggleActiveTab(HeaderType.DASHBOARD)"
          >
            <div
              v-if="activeTab === 'Dashboard'"
              class="app-header__tabs__single__top-bar"
            ></div>
            <span class="app-header__tabs__single__label">{{
              t('tabs.dashboard')
            }}</span>
          </div>
          <div
            :class="[
              'app-header__tabs__single',
              {
                'app-header__tabs__single__active':
                  activeTab === HeaderType.ENTRIESEXITS,
              },
            ]"
            @click="toggleActiveTab(HeaderType.ENTRIESEXITS)"
            @keydown="toggleActiveTab(HeaderType.ENTRIESEXITS)"
          >
            <div
              v-if="activeTab === HeaderType.ENTRIESEXITS"
              class="app-header__tabs__single__top-bar"
            ></div>
            <span class="app-header__tabs__single__label">{{
              t('tabs.workingHours')
            }}</span>
          </div>
          <div
            class="app-header__tabs__single"
            :class="[
              'app-header__tabs__single',
              {
                'app-header__tabs__single__active':
                  activeTab === HeaderType.ERRORHANDLING,
              },
            ]"
            @click="toggleActiveTab(HeaderType.ERRORHANDLING)"
            @keydown="toggleActiveTab(HeaderType.ERRORHANDLING)"
          >
            <div
              v-if="activeTab === HeaderType.ERRORHANDLING"
              class="app-header__tabs__single__top-bar"
            ></div>
            <span class="app-header__tabs__single__label">{{
              t('tabs.errorHandling')
            }}</span>
          </div>
        </div>
      </div>
      <div class="app-header__user">
        <span class="app-header__user__label">{{
          profileStore.profile.name ||
          profileStore.autenthicated.nameUserAuthenticated
        }}</span>
      </div>
    </div>
  </div>
  <AppCancelModal
    v-if="showCancelModal"
    @toggle-modal-status="toggleModalStatus"
    @go-to-login-page="goToLoginPage"
  />
</template>

<style lang="scss">
.app-header__wrapper {
  display: flex;
  flex-direction: column;
}

.app-header__content {
  position: fixed;
  z-index: 1;
  width: calc(100% - 64px);
  height: 68px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 32px;
  background-color: $white;
}

.background-login .app-header__content {
  box-shadow: none;
}

.background-default .app-header__content {
  box-shadow: $neutral-mid-shadow;
}

.app-header__left {
  display: flex;
  align-items: center;
  gap: 32px;
}

.app-header__tabs {
  display: flex;
  flex-direction: row;
  gap: 8px;
}

.app-header__tabs__label {
  font-weight: 700;
  line-height: 130%;
  color: $gray-800;
}

.app-header__tabs__single {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 8px;
  padding-bottom: 16px;
  font-size: 1rem;
  color: $gray-700;
}

.app-header__tabs__single__top-bar {
  width: 100%;
  height: 4px;
  background-color: $blue-500;
  border-radius: 8px;
}

.app-header__tabs__single__active {
  font-weight: 700;
}

.app-header__tabs__single__label {
  padding: 8px 16px;
}

.app-header__tabs__single__active > .app-header__tabs__single__label {
  background-color: $gray-50;
  border-radius: 8px;
}

.app-header__user__label {
  font-weight: 700;
  line-height: 130%;
  color: $gray-800;
  font-size: 0.75rem;
}
</style>

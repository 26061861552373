import axios from 'axios';
import { reactive } from 'vue';
import { defineStore } from 'pinia';
import { UserProfile } from '@/shared/types/profile';
import { HeaderType } from '@/shared/types/generic';
import { ResponsePostHog } from '@vaibe-github-enterprise/kd-kse-fe-commons';

const useProfileStore = defineStore('profile', () => {
  const autenthicated = reactive({
    firstLogin: false,
    nameUserAuthenticated: '',
    roleUserAuthentocated: '',
    tokenUserAuthenticated: '',
    isDashBoard: false,
  });

  const profile = reactive({
    name: '',
    currentTab: HeaderType.DASHBOARD,
    role: '',
    token: '',
    avatarUrl: '',
  } as UserProfile);

  function $reset() {
    profile.name = '';
    profile.role = '';
    profile.token = '';
    profile.avatarUrl = '';
  }

  function getPostHogKey(): Promise<ResponsePostHog> {
    const apiUrl = '/api/configs';
    return axios.get(apiUrl, {
      headers: {
        Authorization: `Bearer ${
          profile.token || autenthicated.tokenUserAuthenticated
        }`,
      },
    });
  }

  function userDisconnected() {
    autenthicated.firstLogin = false;
    autenthicated.isDashBoard = false;
    autenthicated.nameUserAuthenticated = '';
    autenthicated.roleUserAuthentocated = '';
    autenthicated.tokenUserAuthenticated = '';
    $reset();
  }

  async function login(qrCode: string) {
    await axios({
      method: 'post',
      data: qrCode,
      headers: { 'content-type': 'text/plain' },
      url: '/api/profiles',
    }).then((response) => {
      if (!autenthicated.firstLogin) {
        autenthicated.nameUserAuthenticated = response.data.name;
        autenthicated.roleUserAuthentocated = response.data.role;
        autenthicated.tokenUserAuthenticated = response.data.token;
      } else {
        profile.name = response.data.name;
        profile.role = response.data.role;
        profile.token = response.data.token;
        profile.avatarUrl = response.data.avatarUrl;
      }
    });
  }
  return {
    $reset,
    profile,
    login,
    getPostHogKey,
    autenthicated,
    userDisconnected,
  };
});

export default useProfileStore;

import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue"

import { AppButtonType, AppButtonSize } from '@/shared/types/components';
import { IonButton, IonIcon } from '@ionic/vue';
import { PropType } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'AppButton',
  props: {
  type: {
    type: String as PropType<AppButtonType>,
    default: 'default',
  },
  size: {
    type: String as PropType<AppButtonSize>,
    default: undefined,
  },
  label: {
    type: String,
    default: undefined,
  },
  hasIcon: {
    type: Boolean,
    default: false,
  },
  icon: {
    type: String,
    default: undefined,
  },
  iconRight: {
    type: Boolean,
    default: false,
  },
},
  setup(__props) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(IonButton), {
    class: _normalizeClass(['app-button', `app-button--${__props.type}`, `app-button--${__props.size}`]),
    props: _ctx.$props
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(['app-button__content', `app-button__content--${__props.type}`])
      }, [
        (__props.hasIcon && !__props.iconRight)
          ? (_openBlock(), _createBlock(_unref(IonIcon), {
              key: 0,
              icon: __props.icon
            }, null, 8, ["icon"]))
          : _createCommentVNode("", true),
        _createElementVNode("span", {
          class: _normalizeClass([
          'app-button__content__label',
          `app-button__content__label--${__props.type}`,
        ])
        }, _toDisplayString(__props.label), 3),
        (__props.hasIcon && __props.iconRight)
          ? (_openBlock(), _createBlock(_unref(IonIcon), {
              key: 1,
              class: "app-button__iconRight",
              icon: __props.icon
            }, null, 8, ["icon"]))
          : _createCommentVNode("", true)
      ], 2)
    ]),
    _: 1
  }, 8, ["class", "props"]))
}
}

})
import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "app-layout__app-modal" }

import AppButton from '@/components/app/AppButton/AppButton.vue';
import AppModal from '@/components/app/AppModal/AppModal.vue';
import { useI18n } from 'vue-i18n';
import { computed } from 'vue';
import useProfileStore from '@/store/profile/useProfileStore';
import { HeaderType } from '@/shared/types/generic';


export default /*@__PURE__*/_defineComponent({
  __name: 'AppCancelModal',
  emits: ['toggleModalStatus', 'goToLoginPage'],
  setup(__props, { emit: __emit }) {

const { t } = useI18n();
const profileStore = useProfileStore();

const emit = __emit;

const cancelModalTitle = computed(() =>
  profileStore.profile.currentTab === HeaderType.ENTRIESEXITS
    ? t('modal.registo.title')
    : t('modal.cancel.title'),
);

const cancelModalBody = computed(() =>
  profileStore.profile.currentTab === HeaderType.ENTRIESEXITS
    ? t('modal.registo.body')
    : t('modal.cancel.body'),
);

const cancelModalButton = computed(() =>
  profileStore.profile.currentTab === HeaderType.ENTRIESEXITS
    ? t('modal.registo.button.backToRegister')
    : t('buttons.noResume'),
);

const cancelModalButtonLogin = computed(() =>
  profileStore.profile.currentTab === HeaderType.ENTRIESEXITS
    ? t('modal.registo.button.cancelRegisto')
    : t('buttons.confirmCancel'),
);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(AppModal, null, {
      header: _withCtx(() => [
        _createTextVNode(_toDisplayString(cancelModalTitle.value), 1)
      ]),
      body: _withCtx(() => [
        _createTextVNode(_toDisplayString(cancelModalBody.value), 1)
      ]),
      footer: _withCtx(() => [
        _createVNode(AppButton, {
          "data-testid": "close-modal",
          label: cancelModalButton.value,
          onClick: _cache[0] || (_cache[0] = ($event: any) => (emit('toggleModalStatus')))
        }, null, 8, ["label"]),
        _createVNode(AppButton, {
          type: "danger",
          "data-testid": "cancel-flow",
          label: cancelModalButtonLogin.value,
          onClick: _cache[1] || (_cache[1] = ($event: any) => (emit('goToLoginPage')))
        }, null, 8, ["label"])
      ]),
      _: 1
    })
  ]))
}
}

})
import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "app-modal" }
const _hoisted_2 = { class: "app-modal-content" }
const _hoisted_3 = { class: "app-modal-header" }
const _hoisted_4 = { class: "app-modal-body" }
const _hoisted_5 = { class: "app-modal-footer" }

import AppSeparator from '@/components/app/AppSeparator/AppSeparator.vue';

export default /*@__PURE__*/_defineComponent({
  __name: 'AppModal',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _renderSlot(_ctx.$slots, "header")
      ]),
      _createElementVNode("div", _hoisted_4, [
        _renderSlot(_ctx.$slots, "body")
      ]),
      _createVNode(AppSeparator),
      _createElementVNode("div", _hoisted_5, [
        _renderSlot(_ctx.$slots, "footer")
      ])
    ])
  ]))
}
}

})
import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

import { onMounted, ref } from 'vue';
import SplashScreen from '@/components/app/AppSplashScreen/AppSplashScreen.vue';
import MainView from '@/views/MainView/MainView.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props, { expose: __expose }) {

const showSplash = ref(true);
onMounted(() => {
  setTimeout(() => {
    showSplash.value = false;
  }, 3000);
});

__expose({
  showSplash,
});

return (_ctx: any,_cache: any) => {
  return (showSplash.value)
    ? (_openBlock(), _createBlock(SplashScreen, { key: 0 }))
    : (_openBlock(), _createBlock(MainView, { key: 1 }))
}
}

})
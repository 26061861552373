import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "app-header__wrapper" }
const _hoisted_2 = { class: "app-header__content" }
const _hoisted_3 = { class: "app-header__left" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "app-header__tabs" }
const _hoisted_6 = {
  key: 0,
  class: "app-header__tabs__single__top-bar"
}
const _hoisted_7 = { class: "app-header__tabs__single__label" }
const _hoisted_8 = {
  key: 0,
  class: "app-header__tabs__single__top-bar"
}
const _hoisted_9 = { class: "app-header__tabs__single__label" }
const _hoisted_10 = {
  key: 0,
  class: "app-header__tabs__single__top-bar"
}
const _hoisted_11 = { class: "app-header__tabs__single__label" }
const _hoisted_12 = { class: "app-header__user" }
const _hoisted_13 = { class: "app-header__user__label" }

import { Ref, computed, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { vaibeLogo } from '@/shared/constants/icons';
import useProfileStore from '@/store/profile/useProfileStore';
import { useRouter } from 'vue-router';
import useRegisterWorkStore from '@/store/registerWork/useRegisterWorkStore';
import { HeaderType } from '@/shared/types/generic';
import useErrorLogStore from '@/store/errorLog/useErrorLogStore';
import AppCancelModal from '../AppCancelModal/AppCancelModal.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'AppHeader',
  emits: ['toggleActiveTab'],
  setup(__props, { expose: __expose, emit: __emit }) {

const { t } = useI18n();
const profileStore = useProfileStore();
const useRegisteWorkHour = useRegisterWorkStore();
const errorLogStore = useErrorLogStore();
const router = useRouter();

const emit = __emit;

const showCancelModal: Ref<boolean> = ref(false);
const activeTab = ref(HeaderType.DASHBOARD);
const temporaryTab = ref(HeaderType.DASHBOARD);

const hasEditingErrorHandling = computed(
  () =>
    errorLogStore.errorLog.errorCause ||
    errorLogStore.errorLog.errorLocation ||
    errorLogStore.errorLog.errorSubLocation ||
    errorLogStore.errorLog.errorType ||
    errorLogStore.errorLog.palletType,
);

const hasEditingRegisterWorkHour = computed(
  () =>
    useRegisteWorkHour.registerPost.register ||
    useRegisteWorkHour.registerPost.motiveRegister,
);

const clearRegisterWoks = (): void => {
  useRegisteWorkHour.registerPost.register = '';
  useRegisteWorkHour.registerPost.motiveRegister = '';
};

const clearProfile = (): void => {
  profileStore.profile.avatarUrl = '';
  profileStore.profile.name = '';
  profileStore.profile.role = '';
  profileStore.profile.token = '';
};

function toggleActiveTab(tab: HeaderType) {
  if (hasEditingErrorHandling.value && tab !== HeaderType.ERRORHANDLING) {
    showCancelModal.value = true;
    temporaryTab.value = tab;
  } else if (
    hasEditingRegisterWorkHour.value &&
    tab !== HeaderType.ENTRIESEXITS
  ) {
    showCancelModal.value = true;
    temporaryTab.value = tab;
  } else {
    activeTab.value = tab;
    profileStore.profile.currentTab = tab;
    clearRegisterWoks();
    errorLogStore.$reset();
    clearProfile();
    if (tab !== 'Dashboard') {
      router.push('/login');
    }
    emit('toggleActiveTab', activeTab.value);
  }
}

const toggleModalStatus = () => {
  showCancelModal.value = !showCancelModal.value;
};

const goToLoginPage = () => {
  activeTab.value = temporaryTab.value;
  profileStore.profile.currentTab = activeTab.value;
  toggleModalStatus();
  clearRegisterWoks();
  errorLogStore.$reset();
  clearProfile();
};

watch(useRegisteWorkHour.registerPost, (newValue) => {
  if (
    !newValue.register &&
    !newValue.motiveRegister &&
    !profileStore.profile.currentTab
  ) {
    activeTab.value = HeaderType.DASHBOARD;
  }
});

__expose({
  activeTab,
  toggleActiveTab,
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("img", {
            src: _unref(vaibeLogo),
            alt: "vaibe logo"
          }, null, 8, _hoisted_4),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", {
              class: _normalizeClass([
              'app-header__tabs__single',
              { 'app-header__tabs__single__active': activeTab.value === 'Dashboard' },
            ]),
              onClick: _cache[0] || (_cache[0] = ($event: any) => (toggleActiveTab(_unref(HeaderType).DASHBOARD))),
              onKeydown: _cache[1] || (_cache[1] = ($event: any) => (toggleActiveTab(_unref(HeaderType).DASHBOARD)))
            }, [
              (activeTab.value === 'Dashboard')
                ? (_openBlock(), _createElementBlock("div", _hoisted_6))
                : _createCommentVNode("", true),
              _createElementVNode("span", _hoisted_7, _toDisplayString(_unref(t)('tabs.dashboard')), 1)
            ], 34),
            _createElementVNode("div", {
              class: _normalizeClass([
              'app-header__tabs__single',
              {
                'app-header__tabs__single__active':
                  activeTab.value === _unref(HeaderType).ENTRIESEXITS,
              },
            ]),
              onClick: _cache[2] || (_cache[2] = ($event: any) => (toggleActiveTab(_unref(HeaderType).ENTRIESEXITS))),
              onKeydown: _cache[3] || (_cache[3] = ($event: any) => (toggleActiveTab(_unref(HeaderType).ENTRIESEXITS)))
            }, [
              (activeTab.value === _unref(HeaderType).ENTRIESEXITS)
                ? (_openBlock(), _createElementBlock("div", _hoisted_8))
                : _createCommentVNode("", true),
              _createElementVNode("span", _hoisted_9, _toDisplayString(_unref(t)('tabs.workingHours')), 1)
            ], 34),
            _createElementVNode("div", {
              class: _normalizeClass(["app-header__tabs__single", [
              'app-header__tabs__single',
              {
                'app-header__tabs__single__active':
                  activeTab.value === _unref(HeaderType).ERRORHANDLING,
              },
            ]]),
              onClick: _cache[4] || (_cache[4] = ($event: any) => (toggleActiveTab(_unref(HeaderType).ERRORHANDLING))),
              onKeydown: _cache[5] || (_cache[5] = ($event: any) => (toggleActiveTab(_unref(HeaderType).ERRORHANDLING)))
            }, [
              (activeTab.value === _unref(HeaderType).ERRORHANDLING)
                ? (_openBlock(), _createElementBlock("div", _hoisted_10))
                : _createCommentVNode("", true),
              _createElementVNode("span", _hoisted_11, _toDisplayString(_unref(t)('tabs.errorHandling')), 1)
            ], 34)
          ])
        ]),
        _createElementVNode("div", _hoisted_12, [
          _createElementVNode("span", _hoisted_13, _toDisplayString(_unref(profileStore).profile.name ||
          _unref(profileStore).autenthicated.nameUserAuthenticated), 1)
        ])
      ])
    ]),
    (showCancelModal.value)
      ? (_openBlock(), _createBlock(AppCancelModal, {
          key: 0,
          onToggleModalStatus: toggleModalStatus,
          onGoToLoginPage: goToLoginPage
        }))
      : _createCommentVNode("", true)
  ], 64))
}
}

})
import { createApp } from 'vue';
import { createPinia } from 'pinia';
import router from '@/router';
import i18n from '@/plugins/i18n/i18n';
import { IonicVue } from '@ionic/vue';
import App from './App.vue';
import '@/styles/scss/main.scss';
import '@vaibe-github-enterprise/kd-kse-fe-commons/style.css';

const pinia = createPinia();

createApp(App).use(i18n).use(pinia).use(router).use(IonicVue).mount('#app');

import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { reactive } from 'vue';
import { useI18n } from 'vue-i18n';
import AppButton from '@/components/app/AppButton/AppButton.vue';
import AppModal from '@/components/app/AppModal/AppModal.vue';
import useSharedStore from '@/store/shared/useSharedStore';
import useErrorLogStore from '@/store/errorLog/useErrorLogStore';


export default /*@__PURE__*/_defineComponent({
  __name: 'AppAbortConfirmationModal',
  setup(__props, { expose: __expose }) {

const sharedStore = useSharedStore();
const errorLogStore = useErrorLogStore();

const { t } = useI18n();

function resetErrorLog() {
  sharedStore.isModalVisible = false;
  errorLogStore.errorLog = reactive({
    errorLocation: '',
    errorSubLocation: '',
    errorType: '',
    errorCause: '',
    errorCorrectionTime: 0,
    palletType: '',
  });
}

__expose({
  resetErrorLog,
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(AppModal, null, {
    header: _withCtx(() => [
      _createTextVNode(_toDisplayString(_unref(t)('modal.abortError.title')), 1)
    ]),
    body: _withCtx(() => [
      _createTextVNode(_toDisplayString(_unref(t)('modal.abortError.body')), 1)
    ]),
    footer: _withCtx(() => [
      _createVNode(AppButton, {
        type: "vaibe",
        size: "L",
        label: _unref(t)('modal.abortError.button'),
        onClick: resetErrorLog
      }, null, 8, ["label"])
    ]),
    _: 1
  }))
}
}

})